import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Buffer } from 'buffer';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../services/api';

export const AppContext = createContext();

function UserProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEdition, setSelectedEdition] = useState(1);
  const [selectedMenu, setSelectedMenu] = useState('dashboard');
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [selectedMovieTab, setSelectedMovieTab] = useState('fichatecnica');
  const [selectedDirector, setSelectedDirector] = useState(null);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCoproducer, setSelectedCoproducer] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [showAddAssessmentModal, setShowAddAssessmentModal] = useState(false);
  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false);
  const [showEditScheduleIncomeModal, setShowEditScheduleIncomeModal] =
    useState(false);
  const [showEditMovieCatalogModal, setShowEditMovieCatalogModal] =
    useState(false);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [showAddCoProducerModal, setShowAddCoProducerModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [showAddMovieGuest, setShowAddMovieGuest] = useState(false);
  const [selectedMovieGuest, setSelectedMovieGuest] = useState(null);
  const [selectedGalaSchedule, setSelectedGalaSchedule] = useState(null);
  const [authenticatedUser, setAuthenticatedUser] = useState(() => {
    const saved = localStorage.getItem('authenticatedUser');
    const parsedJson = JSON.parse(saved);
    return parsedJson || null;
  });
  const [token, setToken] = useState(() => {
    return JSON.parse(localStorage.getItem('token')) || false;
  });
  const [remember, setRemember] = useState(() => {
    return JSON.parse(localStorage.getItem('remember')) || false;
  });

  const exportData = (data, fileName, type) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleKeyPress = useCallback(event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/^[0-9\b]+$/.test(keyValue) === false) {
      event.preventDefault();
    }
  }, []);

  const getExpirationDate = _token => {
    const { exp } = JSON.parse(Buffer.from(_token.split('.')[1], 'base64'));
    return exp * 1000;
  };

  const getUserIdFromToken = _token => {
    const { user_id } = JSON.parse(Buffer.from(_token.split('.')[1], 'base64'));
    return user_id;
  };

  const isTokenExpired = _token => {
    const expirationDate = getExpirationDate(_token);
    return Date.now() > expirationDate;
  };

  const doLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('remember');
    localStorage.removeItem('authenticatedUser');
    localStorage.clear();

    setToken(false);
    setRemember(false);
    setAuthenticatedUser(null);

    navigate('/');
  };

  const checkTokenExpiration = _token => {
    const isExpired = isTokenExpired(_token);

    if (isExpired) {
      doLogout();
      navigate('/');
    }
  };

  const isAuthenticated = () => {
    return !!localStorage.getItem('token');
  };

  const isAdmin = () => {
    return authenticatedUser.role.id === 1;
  };

  const isEditor = () => {
    return authenticatedUser.role.id === 2;
  };

  const isProgramacao = () => {
    return authenticatedUser.role.id === 3;
  };

  const createCast = async (payload, config) => {
    const { data } = await api.post(`casts`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createLanguage = async (payload, config) => {
    const { data } = await api.post(`languages`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createScreenwriter = async (payload, config) => {
    const { data } = await api.post(`screenwriters`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createProducer = async (payload, config) => {
    const { data } = await api.post(`producers`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createPhotographer = async (payload, config) => {
    const { data } = await api.post(`photographers`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createEditor = async (payload, config) => {
    const { data } = await api.post(`editors`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createMusician = async (payload, config) => {
    const { data } = await api.post(`musicians`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createTechnician = async (payload, config) => {
    const { data } = await api.post(`technicians`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createMounting = async (payload, config) => {
    const { data } = await api.post(`mountings`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createArtDirection = async (payload, config) => {
    const { data } = await api.post(`art-directions`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createCostume = async (payload, config) => {
    const { data } = await api.post(`costumes`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createSoundEditor = async (payload, config) => {
    const { data } = await api.post(`sound_editors`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createSoundDirection = async (payload, config) => {
    const { data } = await api.post(`sound_directions`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createSubproducer = async (payload, config) => {
    const { data } = await api.post(`subproducers`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createExecutiveProducer = async (payload, config) => {
    const { data } = await api.post(`executive_producers`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createScreenerHd1 = async (payload, config) => {
    const { data } = await api.post(`/hd1/tags`, payload, config);
    return { label: data.tag, value: data.id, data };
  };

  const createScreenerHd2 = async (payload, config) => {
    const { data } = await api.post(`/hd2/tags`, payload, config);
    return { label: data.tag, value: data.id, data };
  };

  const createGuest = async (payload, config) => {
    const { data } = await api.post(`guests`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const createAssignedDistributor = async (payload, config) => {
    const { data } = await api.post(`/assigned-distributors`, payload, config);
    return { label: data.name, value: data.id, data };
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useEffect(() => {
    const currentPath = location.pathname + location.search;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: currentPath,
      },
    });
  }, [location]);

  return (
    <AppContext.Provider
      value={{
        token,
        remember,
        isLoading,
        selectedEdition,
        selectedMenu,
        selectedSubMenu,
        selectedDirector,
        selectedDistributor,
        selectedCompany,
        selectedCoproducer,
        selectedImage,
        authenticatedUser,
        selectedMovieTab,
        selectedAssessment,
        showAddAssessmentModal,
        selectedSchedule,
        showAddMovieGuest,
        selectedMovieGuest,
        selectedGalaSchedule,
        showAddScheduleModal,
        showEditScheduleModal,
        showEditScheduleIncomeModal,
        showEditMovieCatalogModal,
        showAddCompanyModal,
        showAddCoProducerModal,
        setToken,
        setRemember,
        checkTokenExpiration,
        doLogout,
        setIsLoading,
        isAuthenticated,
        isAdmin,
        isEditor,
        isProgramacao,
        setSelectedEdition,
        setSelectedMenu,
        setSelectedSubMenu,
        createLanguage,
        createCast,
        createScreenwriter,
        createProducer,
        createPhotographer,
        createEditor,
        createMusician,
        createTechnician,
        createMounting,
        createArtDirection,
        createCostume,
        createSoundEditor,
        createSoundDirection,
        createSubproducer,
        createExecutiveProducer,
        createAssignedDistributor,
        handleKeyPress,
        setSelectedDirector,
        setSelectedDistributor,
        setSelectedCompany,
        setSelectedCoproducer,
        setSelectedImage,
        getUserIdFromToken,
        setAuthenticatedUser,
        setSelectedMovieTab,
        setSelectedAssessment,
        setShowAddAssessmentModal,
        setShowAddScheduleModal,
        setShowEditScheduleModal,
        setShowEditScheduleIncomeModal,
        setShowEditMovieCatalogModal,
        setShowAddCompanyModal,
        setShowAddCoProducerModal,
        createScreenerHd1,
        createScreenerHd2,
        setSelectedSchedule,
        setShowAddMovieGuest,
        setSelectedMovieGuest,
        setSelectedGalaSchedule,
        createGuest,
        exportData,
        reorder,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(
      'You must surround your components with a CustomerProvider before call useCustomer',
    );
  }

  return context;
};

export default UserProvider;
